<template>
  <div class="rc-container">
    
    
    <div class="rc-row" v-if="!isLoading">
      <div class="rc-box-col-15">
        <InspectionList v-if="domain" :isAdmin="true" :start="startSub"/>
      </div>
    </div>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>
    <SpacerRow />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

// import InspectionList from "@/portals/customer/operator/views/inspection/list/ListView.vue"
import InspectionList from "@/views/portal/inspections/list/InspectionList.vue"
import SpacerRow from '@/components/row/SpacerRow.vue';
import ConstUtils  from '@/utils/ConstUtils.js';
//import Company from "@/domain/model/company/Company.js";

export default {
  name: 'portals-admin-inspections',
  components: {
    InspectionList,
    SpacerRow, LoadingRow,
  },
  props: {
    isAdmin: { type: Boolean, default: true },
  },
  data() {
    return {
      startSub: false,
      
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,

    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                 ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
      ConnectionUtils.withAdminCompany(this);
      this.startSub = true;
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>